<template>
  <div>
    <Card :title="'Usuário'">
      <b-row class="mt-3">
        <b-col md="4">
          <label>Nome</label>
          <b-input v-model="model.name"></b-input>
        </b-col>
        <b-col md="4">
          <label>Email</label>
          <b-input v-model="model.email"></b-input>
        </b-col>
        <b-col>
          <label>Unidade</label>
          <b-form-select
            v-model="model.unity_id"
            :options="unitiesOptions"
            @change="unity_change"
          ></b-form-select>
        </b-col>
        <b-col v-if="model.unity_id !== ''">
          <label>Grupo</label>
          <b-form-select
            v-model="model.group_id"
            :options="groupOptions"
          ></b-form-select>
        </b-col>
      </b-row>
      <hr />
      <div class="d-flex justify-content-end mb-3">
        <b-button v-if="edit" variant="primary-custom" @click="update_user"
          >Alterar</b-button
        >
        <b-button v-else variant="primary-custom" @click="submit_user"
          >Criar novo</b-button
        >
      </div>
    </Card>
    <SweetModal ref="user_exists" overlay-theme="dark" icon="error">
      <span class="lead mt-4">Usuário já existe</span>
    </SweetModal>
    <SweetModal ref="unity_not_exists" overlay-theme="dark" icon="error">
      <span class="lead mt-4"
        >Necessário criar um grupo para a unidade selecionada.</span
      >
    </SweetModal>
    <SweetModal ref="submit_error" overlay-theme="dark" icon="error">
      <div class="d-flex flex-column">
        <span class="lead">Campos obrigatórios</span>
        <span class="" v-for="(error, idx) in errors" :key="idx">{{
          error
        }}</span>
      </div>
    </SweetModal>    
  </div>
</template>

<script>
import schemasFields from "../schema/AddEditFormFields";
export default {
  data() {
    return {
      errors: [],
      edit: false,
      model: {
        unity_id: "",
        name: "",
        email: "",
        group_id: "",
        active: "1",
      },
      groupOptions: [],
      validate: [
        { key: "unity_id", description: "Unidade" },
        { key: "name", description: "Nome" },
        { key: "email", description: "Email" },
        { key: "group_id", description: "Grupo" },
      ],
    };
  },
  computed: {
    unitiesOptions() {
      return _.map(this.$store.state.users.unities, (unity) => {
        return { value: unity.id, text: unity.company_name };
      });
    },
    schemas() {
      const unitiesOptions = _.map(this.unities, (unity) => {
        return { value: unity.id, text: unity.company_name };
      });
      return schemasFields(unitiesOptions);
    },
  },
  methods: {
    async unity_change() {
      this.model.group_id = "";
      const response = await this.$store.dispatch(
        "users/get_groups_by_unity_id",
        this.model.unity_id
      );
      this.groupOptions = _.map(response, (option) => {
        return { value: option.id, text: option.description };
      });
    },
    async update_user() {
      this.errors = [];
      _.forEach(this.validate, (key) => {
        if (!this.model[key.key]) {
          this.errors.push(key.description);
        }
      });
      if (this.errors.length > 0) {
        this.$refs.submit_error.open();
      } else {
        const response = await this.$store.dispatch(
          "users/update_user",
          this.model
        );
        if (response) {
          this.$router.go(-1);
        } else {
          this.$refs.user_exists.open();
        }
      }
    },
    async submit_user() {
      this.errors = [];
      _.forEach(this.validate, (key) => {
        if (!this.model[key.key]) {
          this.errors.push(key.description);
        }
      });
      if (this.errors.length > 0) {
        this.$refs.submit_error.open();
      } else {
        const response = await this.$store.dispatch(
          "users/save_user",
          this.model
        );
        if (response) {
          this.$router.go(-1);
        } else {
          this.$refs.user_exists.open();
        }
      }
    },
  },
  async mounted() {
    const userId = this.$route.params.userId;
    if (userId) {
      this.edit = true;
      const response = await this.$store.dispatch(
        "users/get_user_by_id",
        userId
      );
      if (response.id) {
        this.model = response;
      }
      const groups = await this.$store.dispatch(
        "users/get_groups_by_unity_id",
        this.model.unity_id
      );
      this.groupOptions = _.map(groups, (option) => {
        return { value: option.id, text: option.description };
      });
    }
    if (this.unitiesOptions.length == 1) {
      this.model.unity_id = process.env.VUE_APP_APPLICATION_ID;
    }
  },
};
</script>

<style>
</style>